@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,500;1,600;1,700&family=Maven+Pro&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
  --background-color: #ffeddf;
  --primary-color: #f07e21;
  --secondary-color: #f7dc89;
  --secondary-color-22: #f7dc8922;
  --secondary-color-33: #f7dc8933;
  --secondary-color-44: #f7dc8944;
  --secondary-color-55: #f7dc8955;
  --secondary-color-66: #f7dc8966;
  --secondary-color-77: #f7dc8977;
  --secondary-color-88: #f7dc8988;
  --light-text: #dddddd;
  --dark-text: #333333;
  --off-white: #f0efe4;
  --off-white-tp: #f0efe4f9;
  --light-grey: #dddddd;

  --footer-spacing: 5rem;

  scroll-behavior: smooth;
}

body {
  background-color: var(--background-color);
  color: var(--dark-text);
  font-family: 'Maven Pro', sans-serif;
}

h1 {
  font-family: 'Source Sans Pro', sans-serif;
}

h2 {
  font-family: 'Lora', serif;
}

a {
  text-decoration: none;
  color: inherit;
}